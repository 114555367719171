import React, { useEffect, useState } from "react";
import { Alert, Divider, InputNumber, Button, Form, Input, Select, Switch, Card, Row, Col, Typography, Radio } from "antd";
import { PlusOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";

const { Title } = Typography;

const AddProductCard = ({
    baseUrl, orderId, getOrderDetails, getOrderSuppliers, suppliers, catagories, filteredProducts,
    catogeryCode, supplierCode, setSupplierCode, setCatogeryCode, product, setProduct, amount,
    setAmount, isExternalProduct, setIsExternalProduct, price, setPrice, storeId, setStoreId,
    disabled, setDisabled, setwaiting, orderProductID, setnewAmount, setshowConfirm, isEdit, waiting
}) => {
    const [errorName, setErrorName] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(product);
    }, [product, form]);

    const headers = {
        Authorization: "Bearer " + localStorage.getItem("casherToken"),
    };

    const handleSelectProduct = (id) => {
        const selectedProduct = filteredProducts.find((item) => item.id === id);
        setProduct(selectedProduct);
        setPrice(selectedProduct?.sellingPrice);
        setStoreId(selectedProduct?.stores[0]?.storeId);
    };

    const handleAddProductCancel = () => {
        setAmount();
        setProduct(null);
        setSupplierCode(null);
        setCatogeryCode(null);
        setStoreId();
        setPrice();
        setDisabled(true);
    };

    const handleCancel = () => {
        form.resetFields();
        setProduct();
        setIsExternalProduct(false);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                isEdit ? editExternalProduct({
                    ...values, orderProductId: product.orderProductID, productName: values.ProductName
                }) :
                    addExternal({
                        ...values,
                        orderID: orderId,
                        externalProduct: true,
                    });
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const addExternal = async (data) => {
        setwaiting(true);
        try {
            await axios.post(`${baseUrl}/api/Orders/AddExternalProduct`, data, { headers });
            getOrderDetails();
            handleCancel();
        } catch (err) {
            console.log(err);
        } finally {
            setwaiting(false);
        }
    };

    const editProduct = async () => {
        setErrorName(null);
        if (product === undefined) {
            setErrorName("من فضلك اختر المنتج");
            return;
        } else if (amount === undefined || amount <= 0) {
            setErrorName("من فضلك ادخل الكميه");
            return;
        }
        setwaiting(true);

        let data = {
            orderProductID: orderProductID,
            productID: product.id,
            amount: amount,
            price: price,
        };
        await axios
            .put(`${baseUrl}/api/Orders/EditProductInOrder`, data, { headers })
            .then((response) => {
                getOrderDetails();
                getOrderSuppliers();
                setwaiting(false);

                if (amount > response?.data?.amount) {
                    setnewAmount(response?.data?.amount);
                    setshowConfirm(true);
                    return;
                }
                handleAddProductCancel();
                setwaiting(false);
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.status === 500) {
                    setErrorName('internal server error')
                } else {
                    setErrorName(Array.isArray(error?.response?.data) ? error.response.data[0] : 'there is an error please check the console');
                }
            });
    };

    const handleAddok = () => {
        isEdit ? editProduct() : addProduct();
    };

    const addProduct = async () => {
        setErrorName(null);
        if (!product) {
            setErrorName("من فضلك اختر المنتج");
            return;
        } else if (!amount || amount <= 0) {
            setErrorName("من فضلك ادخل الكميه");
            return;
        }
        setwaiting(true);

        const data = {
            orderId: orderId,
            price: price,
            productID: product.id,
            amount: amount,
            storeId: storeId,
        };

        try {
            const response = await axios.post(`${baseUrl}/api/Orders/AddProduct`, data, { headers });
            getOrderDetails();
            getOrderSuppliers();
            if (amount > response?.data?.amount) {
                setErrorName(`لقد قمت بطلب ${amount} قطعه والمتوفر في المخزن ${response.data.amount}`);
                return;
            }
            handleAddProductCancel();
        } catch (error) {
            console.log(error);
            setErrorName(
                error?.response?.status === 500
                    ? "internal server error"
                    : Array.isArray(error?.response?.data)
                        ? error.response.data[0]
                        : "there is an error please check the console"
            );
        } finally {
            setwaiting(false);
        }
    };

    const editExternalProduct = async (values) => {
        try {
            console.log('Editing Product:', values);
            await axios.put(`${baseUrl}/api/Orders/EditExternalProduct`, values, { headers });
            getOrderDetails();
            handleCancel();
        } catch (error) {
            console.error('Error editing external product:', error);
        }
    };

    return (
        <Card className="w-25" bordered={false} style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <div className="card-body">
                <Row justify="space-between" align="middle" className="w-100 mb-4">
                    <Title level={5}>{isExternalProduct ? "أضافة منتج خارجي" : "أضافة منتج"}</Title>
                    <Radio.Group
                    size=""
                        value={isExternalProduct ? "external" : "internal"}
                        onChange={(e) => setIsExternalProduct(e.target.value === "external")}
                        buttonStyle="solid"
                    >
                        <Radio.Button value="internal">منتج داخلي</Radio.Button>
                        <Radio.Button value="external">منتج خارجي</Radio.Button>
                    </Radio.Group>
                </Row>
                {isExternalProduct ? (
                    <Form form={form} layout="vertical" name="externalProductForm" >
                        <Form.Item
                            name="ProductName"
                            rules={[{ required: true, message: "Please input the name!" }]}
                        >
                            <Input size="large" placeholder="الاسم" />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={18}>
                                <Form.Item
                                    name="price"
                                    rules={[{ required: true, message: "Please input the selling price!" }]}
                                >
                                    <InputNumber size="large" min={0} style={{ width: "100%" }} placeholder="السعر" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: "Please input the amount!" }]}
                                >
                                    <InputNumber size="large" min={0} style={{ width: "100%" }} placeholder="الكميه" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="supplierID" >
                            <Select
                                placeholder={"المورد"}
                                optionFilterProp="label"
                                options={suppliers?.map((item) => ({
                                    label: item.name,
                                    value: item.id || item.code,
                                }))}
                                className="custom-dropdown w-100 h-100"
                                showSearch
                                allowClear
                                onClear={() => setSupplierCode("")}
                                size="large"
                                value={supplierCode}
                            />
                        </Form.Item>
                        <Divider />
                        <div className="d-flex flex-column gap-2">
                               <Button size="large" type="primary" onClick={handleOk} loading={waiting} icon={<PlusOutlined />}>
                                    أضافه
                                </Button>
                                <Button onClick={handleCancel} danger icon={<CloseOutlined />}>
                                    الغاء
                                </Button>
                        </div>
                    </Form>
                ) : (
                    <>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Select
                                    placeholder={"المورد"}
                                    optionFilterProp="label"
                                    options={suppliers?.map((item) => ({
                                        label: item.name,
                                        value: item.id ? item.id : item.code,
                                    }))}
                                    className="custom-dropdown w-100 h-100"
                                    showSearch
                                    onChange={(e) => setSupplierCode(e)}
                                    allowClear
                                    onClear={() => setSupplierCode("")}
                                    size="large"
                                    value={supplierCode}
                                />
                            </Col>
                            <Col span={12}>
                                <Select
                                    placeholder={"الفئة"}
                                    optionFilterProp="label"
                                    options={catagories?.map((item) => ({
                                        label: item.name,
                                        value: item.id ? item.id : item.code,
                                    }))}
                                    className="custom-dropdown w-100 h-100"
                                    showSearch
                                    onChange={(e) => setCatogeryCode(e)}
                                    allowClear
                                    onClear={() => setCatogeryCode("")}
                                    size="large"
                                    value={catogeryCode}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Select
                            placeholder={"المنتج"}
                            optionFilterProp="label"
                            options={filteredProducts?.map((product) => ({
                                label: `${product.name} (${product.supplierName})`,
                                value: product.id,
                            }))}
                            className="w-100 mb-2"
                            size="large"
                            showSearch
                            onChange={(e) => handleSelectProduct(e)}
                            allowClear
                            value={product?.id}
                            onClear={() => setProduct("")}
                        />
                        <Row gutter={16} align="middle">
                            <Col span={18}>
                                <Select
                                    placeholder={"المخزن"}
                                    optionFilterProp="label"
                                    options={product?.stores?.map((store) => ({
                                        label: `${store.storeName}`,
                                        value: store.storeId,
                                    }))}
                                    className="w-100"
                                    size="large"
                                    showSearch
                                    onChange={(e) => setStoreId(e)}
                                    allowClear
                                    value={storeId}
                                    onClear={() => setProduct("")}
                                />
                            </Col>
                            <Col span={6}>
                                <InputNumber
                                    value={amount}
                                    onChange={(e) => setAmount(e)}
                                    min={1}
                                    placeholder="الكميه"
                                    controls={false}
                                    className="w-100"
                                    size="large"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle" className="mt-2">
                            <Col span={20}>
                                <InputNumber
                                    value={price}
                                    onChange={(e) => setPrice(e)}
                                    min={1}
                                    placeholder="السعر"
                                    disabled={disabled}
                                    controls={false}
                                    className="w-100 bg-white"
                                    size="large"
                                />
                            </Col>
                            <Col span={4}>
                                <Button type="link" onClick={() => setDisabled(false)} icon={<EditOutlined />} />
                            </Col>
                        </Row>
                        <Divider />
                        <div className="d-flex flex-column gap-2">
                                <Button size="large" type="primary" onClick={handleAddok} loading={waiting} icon={<PlusOutlined />}>
                                    أضافه
                                </Button>
                                <Button onClick={handleAddProductCancel} danger icon={<CloseOutlined />}>
                                    الغاء
                                </Button>
                        </div>
                    </>
                )}
                {errorName && <Alert message={errorName} type="error" className="mt-2" />}
            </div>
        </Card>
    );
};

export default AddProductCard;