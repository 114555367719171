import React, { useEffect } from 'react';
import {  Result } from 'antd';
export default function Error404() {


    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return<>
    <div className='vh-100'>
    <div className="d-flex justify-content-center align-items-center">
    <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you look for doesn't exist."
    // extra={<Button type="primary" onClick={()=>{navigate('/home')}}>Back Home</Button>}
    />
    </div>
    </div>
    </>
}
