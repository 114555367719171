import { Alert, Divider, InputNumber, Spin, notification, Button, Modal, Select, Input, Form } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrderModal from "../../CasherHome/CreateOrderModal"

export default function ReturnOrder({ baseUrl }) {
  const [waiting, setwaiting] = useState();
  const [product, setproduct] = useState();
  const [Loading, setLoading] = useState();
  const [Order, setOrder] = useState();
  const [per, setper] = useState(0);
  const [mainOrder, setmainOrder] = useState();
  const [errorName, setErrorName] = useState(null);
  const [SelectedProduct, setSelectedProduct] = useState([]);
  let navigate = useNavigate();
  let { orderId, returnID } = useLocation().state ?? { orderId: null };
  const [addProductVisible, setaddProductVisible] = useState(false);
  const [amount, setamount] = useState();
  const [ShowAddModal, setShowAddModal] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [addExternalProductVisible, setAddExternalProductVisible] = useState(false);
  const [form] = Form.useForm();

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("casherToken"),
  }

  useEffect(() => {
    if (orderId === null || returnID === null) {
      navigate("/casher");
    } else {
      getOrderDetails();
      getMainOrderDetails()
      getSuppliers(); // Fetch suppliers for external product

    }
  }, []);
  async function getSuppliers() {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/Suppliers/GetAll`, { headers });
      setSuppliers(response.data.suppliers);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    } finally {
      setLoading(false);
    }
  }

  async function addExternalProduct(values) {
    setwaiting(true);
    try {
      values.orderId=returnID
      await axios.post(`${baseUrl}/api/Orders/AddExternalProduct`, values, { headers });
      notification.success({
        message: "تمت الإضافة",
        description: "تمت إضافة المنتج الخارجي بنجاح",
      });
      getOrderDetails(); // Refresh order details
      setAddExternalProductVisible(false); // Close modal
      form.resetFields(); // Reset form fields
    } catch (error) {
      console.error("Error adding external product:", error);
      notification.error({
        message: "خطأ",
        description: "حدث خطأ أثناء إضافة المنتج الخارجي",
      });
    } finally {
      setwaiting(false);
    }
  }

  async function getMainOrderDetails() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Orders/GetOrderDetails?OrderID=${orderId}`, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        console.log('order', response.data);

        setmainOrder(response.data.orders);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function getOrderDetails() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Orders/GetOrderDetails?OrderID=${returnID}`, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        console.log('return order', response.data);

        setOrder(response.data.orders);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function OrderDone() {
    let data = {
      orderID: returnID,
    };
    await axios
      .post(`${baseUrl}/api/Orders/CompleteReturnOrder`, data, {
        headers,
      })
      .then((res) => {
        PrintOrder()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function PrintOrder() {
    window.open(`/casher/blank/${returnID}`, '_blank');
    navigate("/casher");

    // navigate("/casher/print-order", { state: { Order: Order } });
  }


  async function cancelOrder() {
    setLoading(true);
    let data = {
      orderID: returnID,
    };
    await axios
      .post(`${baseUrl}/api/Orders/CancelOrder`, data, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        navigate("/casher");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function deleteProductFromOrder(productId) {
    setwaiting(true);
    try {
      const response = await axios.delete(
        `${baseUrl}/api/Orders/DeleteProductFromOrder?OrderProductId=${productId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("casherToken"),
          },
        }
      );

      setLoading(false);
      getOrderDetails(); // Refresh order details after deletion
      handleAddProductCancel();
      setwaiting(false);

      notification.success({
        message: "تم الحذف",
        description: "تم حذف المنتج بنجاح",
      });
    } catch (error) {
      setLoading(false);
      console.error("Error deleting product", error);
      notification.error({
        message: "خطأ",
        description: "حدث خطأ أثناء حذف المنتج",
      });
    }
    setwaiting(false);
  }
  async function addDis() {
    let data = {
      orderId: returnID,
      discount: per,
    };
    await axios
      .post(`${baseUrl}/api/Orders/AddManualDiscount`, data, {
        headers,
      })
      .then((response) => {
        getOrderDetails();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  async function addProduct() {
    setErrorName(null);
    if (product === undefined) {
      setErrorName("من فضلك اختر المنتج");

      return;
    } else if (amount === undefined || amount < 1) {
      setErrorName("من فضلك ادخل الكميه");

      return;
    }
    setwaiting(true);

    let data = {
      "orderId": returnID,
      "productsToReturn": [
        {
          "orderProductId": product,
          "returnAmount": amount
        }
      ]
    }
    await axios
      .post(`${baseUrl}/api/Orders/AddProductInOrderReturn`, data, {
        headers,
      })
      .then((response) => {
        getOrderDetails();
        setwaiting(false);

        handleAddProductCancel();
        setwaiting(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 500) {
          setErrorName('internal server error')
        }
        else {
          setErrorName(Array.isArray(error?.response?.data) ? error.response.data[0] : 'there is an error please check the console');
        }
      });
  }


  const handleAddProductCancel = () => {
    setaddProductVisible(false);
    setamount();
    setproduct(null);

  };


  function handleSelectProduct(orderProductID) {
    setproduct(orderProductID);
    setSelectedProduct(
      mainOrder?.items?.filter((item) => {
        return item.orderProductID === orderProductID;
      })[0]
    );
    setamount(mainOrder?.items?.filter((item) => {
      return item.orderProductID === orderProductID;
    })[0].amount)
  }




  function handleAddok() {
    addProduct()
  }





  return (
    <div className="container mt-5">
      {Loading ? <Spin fullscreen /> : ""}
      <div className="d-flex justify-content-around align-items-center gap-3">
        <div className="card col-12 p-4 rounded-2">
          <div className="d-flex justify-content-between align-items-center">

            <div className="d-flex flex-column">
              {Order?.customerName === "none" ? null : (
                <h5>العميل: {Order?.customerName}</h5>
              )}
              {Order?.technincalName === "none" ? null : (
                <h5>الفني: {Order?.technincalName}</h5>
              )}
              {Order?.customerDescription && (
                <h5>تفاصيل الفاتوره: {Order?.customerDescription}</h5>
              )}
            </div>

            {Order ?
              <CreateOrderModal baseUrl={baseUrl} open={ShowAddModal} setopen={setShowAddModal} setLoading={getOrderDetails} isEdit={true} orderID={orderId} Order={Order} />
              :
              null
            }

          </div>
          <Divider className="mt-0" />

          <div className="d-flex justify-content-end gap-3">
            <button
              className="btn btn-primary rounded-5 px-3"
              onClick={() => setaddProductVisible(true)}
            >
              استرجاع منتج
            </button>
            <button
              className="btn btn-secondary rounded-5 px-3"
              onClick={() => setAddExternalProductVisible(true)}
            >
              إضافة منتج خارجي
            </button>

          </div>

          <div>
            <h5>الطلبيه : </h5>
            <div className="overflow-auto" style={{ maxHeight: "52vh" }}>
              <table className="table table-bordered table-sm custom-table  ">
                <thead>
                  <tr>
                    <th className="text-center ">اسم المنتج</th>
                    <th className="text-center ">الكمية</th>
                    <th className="text-center ">السعر قبل الخصم</th>
                    <th className="text-center ">السعر بعد الخصم</th>
                    <th className="text-center ">الاجمالي</th>
                    <th className="text-center ">حذف </th>
                  </tr>
                </thead>
                <tbody>
                  {Order?.items?.map((element) => {
                    return (
                      <tr>
                        <td>{element.name}</td>
                        <td>{element.amount}</td>
                        <td>{element.price}</td>
                        <td>{element.newPrice}</td>
                        <td>{element.total}</td>
                        <td>


                          <Button
                            type="link"
                            onClick={() =>
                              deleteProductFromOrder(element.orderProductID)
                            }
                            style={{ color: "red" }}
                          >
                            <i className="fa-sharp fa-solid fa-circle-xmark fs-5"></i>
                          </Button>

                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="">
            <h5>الاجمالي : {Order?.totalBeforeDiscount} جنيه</h5>
            {Order?.totalAfterDiscount === Order?.totalBeforeDiscount ? null : (
              <h5>الأجمالي بعد الخصم : {Order?.totalAfterDiscount} جنيه</h5>
            )}
          </div>
          <div className="d-flex justify-content-between gap-3 align-items-center">
            <span>

              <label htmlFor="per" className=" fw-semibold">
                خصم على الفاتوره:
              </label>
              <InputNumber
                value={per}
                id="per"
                min={0}
                size="large"
                placeholder="نسبة الخصم"
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace("%", "")}
                onChange={(e) => setper(e)}
                className="mx-2"
                width={5}
                controls={false}
              />
              <button
                className="btn btn-primary rounded-5 "
                onClick={addDis}
              >
                أضافة
              </button>
            </span>
            <span>
              <button className="btn btn-red rounded-5" onClick={cancelOrder}>
                الغاء الطلب
              </button>
              <button
                className="btn btn-success rounded-5 me-2"
                onClick={OrderDone}
              >
                تأكيد الطلب <i className="fa fa-check"></i>
              </button>
            </span>
          </div>
        </div>
      </div>


      <Modal
        open={addProductVisible}
        onOk={handleAddok}
        onCancel={handleAddProductCancel}
        confirmLoading={waiting}
        centered
        closeIcon={false}
        footer={false}
        title='أسترجاع منتج'
      >

        <div className="row align-items-center">
          <Select
            placeholder={"المنتج"}
            optionFilterProp="label"
            options={mainOrder?.items?.map((product) => ({
              label: product.name,
              value: product.orderProductID,
            }))}
            className=" col px-1"
            size="large"
            showSearch
            onChange={(e) => {
              handleSelectProduct(e);
            }}
            allowClear
            value={product}
            onClear={() => setproduct("")}
          />
          <InputNumber
            value={amount}
            onChange={(e) => setamount(e)}

            max={SelectedProduct?.amount}
            min={1}
            placeholder="الكميه"
            controls={false}
            className="col-3"
            size="large"
          />
        </div>
        <Divider />
        <div className="d-flex justify-content-end align-items-end">
          <div className="d-flex gap-2">
            <Button onClick={handleAddProductCancel}>الغاء</Button>
            <Button type="primary" onClick={handleAddok} loading={waiting}>
              أضافه
            </Button>
          </div>
        </div>

        {errorName === null || errorName === "" ? (
          <></>
        ) : (
          <Alert message={errorName} type="error" className="" />
        )}
      </Modal>


      <Modal
        open={addExternalProductVisible}
        onCancel={() => {
          setAddExternalProductVisible(false);
          form.resetFields(); // Reset form fields when modal is closed
        }}
        centered
        closeIcon={false}
        footer={false}
        title="إضافة منتج خارجي"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={addExternalProduct} // Trigger addExternalProduct on form submission
        >
          <Form.Item
            name="productName"
            label="اسم المنتج"
            rules={[{ required: true, message: "من فضلك أدخل اسم المنتج" }]}
          >
            <Input placeholder="اسم المنتج" size="large" />
          </Form.Item>
          <Form.Item
            name="amount"
            label="الكمية"
            rules={[{ required: true, message: "من فضلك أدخل الكمية" }]}
          >
            <InputNumber
              placeholder="الكمية"
              min={1}
              className="w-100"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="price"
            label="السعر"
            rules={[{ required: true, message: "من فضلك أدخل السعر" }]}
          >
            <InputNumber
              placeholder="السعر"
              min={0}
              className="w-100"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="supplierID"
            label="المورد"
            rules={[{ required: true, message: "من فضلك اختر المورد" }]}
          >
            <Select
              placeholder="المورد"
              options={suppliers.map((supplier) => ({
                label: supplier.name,
                value: supplier.id,
              }))}
              className="w-100"
              size="large"
              showSearch
              allowClear
            />
          </Form.Item>
          <Divider />
          <div className="d-flex justify-content-end align-items-end">
            <div className="d-flex gap-2">
              <Button onClick={() => setAddExternalProductVisible(false)}>الغاء</Button>
              <Button type="primary" htmlType="submit" loading={waiting}>
                أضافه
              </Button>
            </div>
          </div>
        </Form>
      </Modal>;


    </div>
  );
}