import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Checkbox, DatePicker, Input, message, Modal, Pagination, Popconfirm, Radio, Select, Spin, Tooltip } from "antd";
import axios from "axios";
import { PlusCircleOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
function LastOrders({ baseUrl }) {
    const [orders, setOrders] = useState([]);
    const [subOrders, setsubOrders] = useState([]);
    const [customerID, setcustomerID] = useState();
    const [technicianID, settechnicianID] = useState();
    const [technicians, settechnicians] = useState();
    const [Customers, setCustomers] = useState();
    const [Loading, setLoading] = useState(false);
    const [statues, setstatues] = useState();
    const [from, setfrom] = useState();
    const [to, setto] = useState();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(1);
    const [OrderID, setOrderID] = useState();
    const [numofPages, setnumofPages] = useState();
    const [checked, setChecked] = useState(false);
    const [showSubOrders, setshowSubOrders] = useState(false);

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    useEffect(() => {
        getTechnicians();
        getCustomers();
    }, []);


    useEffect(() => {
        getOrders();
    }, [customerID, statues, technicianID, to, from, current, checked]);
    async function getTechnicians() {
        await axios
            .get(`${baseUrl}/api/Technicians/GetAll`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("casherToken"),
                },
            })
            .then((response) => {
                console.log("Technicians res", response);
                settechnicians(response.data.technicians);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    const onChange = (e) => {
        console.log('checked = ', e.target.checked);
        setChecked(e.target.checked);
    };
    async function getCustomers() {
        await axios
            .get(`${baseUrl}/api/Customers/GetAll`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("casherToken"),
                },
            })
            .then((response) => {
                console.log("Technicians res", response);
                setCustomers(response.data.customers);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    function PaginationChange(e) {
        setCurrent(e);
    }

    async function CreateOrder(isReturn) {

        setLoading(true)
        let data = {
            "existingOrderId": OrderID,
            "isReturn": isReturn
        }
        console.log(data);

        await axios.post(`${baseUrl}/api/Orders/CreateOrderFromExistOrder`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('return res', response);
                isReturn ? navigate('/casher/return-order', { state: { orderId: OrderID, returnID: response.data.returnOrderId } }) : navigate('/casher/add-order', { state: { orderId: response.data.returnOrderId } })
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                message.error(error?.response?.data[0])


            })
    }


    async function getOrders() {
        setOrders([])
        console.log('statues', statues);
        setLoading(true);
        await axios
            .get(
                `${baseUrl}/api/Orders/GetOrdersByStatus?PageNumber=${current}${customerID ? `&CustomerId=${customerID}` : ""
                }${technicianID ? `&TechnicalId=${technicianID}` : ""}${statues >= 0 ? `&Status=${statues}` : ""
                }&DateFrom=${from ? `${from}` : checked ? '' : '2024-8-26'}${to ? `&DateTo=${to}` : ""}`,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            )
            .then((response) => {
                setLoading(false);
                console.log("orders res", response);
                console.log("orders", response.data.orders);
                setnumofPages(response?.data?.totalPages);
                if (current > response?.data?.totalPages && response?.data?.totalPages !== 0) {
                    setCurrent(response?.data?.totalPages)
                }
                setOrders(response.data.orders);
                console.log(orders);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    async function getSubOrders(id) {
        setsubOrders([])
        console.log('statues', statues);
        setLoading(true);
        await axios.get(
            `${baseUrl}/api/Orders/GetSubOrders?OrderId=${id}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("casherToken"),
                },
            }
        )
            .then((response) => {
                setLoading(false);
                console.log("orders res", response);
                setsubOrders(response.data.orders);
                setshowSubOrders(true)
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }
    async function getorderById(id) {
        if (id === '') {
            return
        }
        setLoading(true);
        await axios
            .get(
                `${baseUrl}/api/Orders/GetOrderById?OrderId=${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            )
            .then((response) => {
                setLoading(false);
                console.log("orders res", response);
                console.log("orders", response.data.orders);

                setOrders([response.data]);
                console.log(orders);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                if (error?.response?.status === 404) {
                    message.error('هذا الطلب غير موجود')
                }
            });
    }

    const handleCancel = async (id, existingOrderId) => {
        try {
            let data = {
                orderID: id,
            };
            const response = await axios.post(
                `${baseUrl}/api/Orders/CancelOrder`,
                data,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("casherToken"),
                    },
                }
            );
            console.log("Delete operation successful", response);
            existingOrderId ? getSubOrders(existingOrderId) : getOrders();

        } catch (error) {
            console.error("Error deleting order:", error);
        }

        // Add delete logic here
    };
    const handleDelete = async (id,existingOrderId) => {

        await axios.delete(`${baseUrl}/api/Orders/DeleteOrder?OrderId=${id}`,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("casherToken"),
                },
            }
        ).then((res) => {
            console.log("Delete operation successful", res);
           existingOrderId? getSubOrders(existingOrderId): getOrders();
        })
            .catch((error) => {
                console.error("Error deleting order:", error);
            })

        // Add delete logic here
    };

    const handleUpdate = async (id, name) => {
        navigate("/casher/add-order", { state: { orderId: id } });
    };

    return (
        <>
            {Loading ? <Spin fullscreen /> : ""}
            <div className="overflow-x-auto container-fluid ">
                <div className="row align-items-center   w-100  justify-content-evenly my-4">
                    <span className="col-2">
                        <label htmlFor="" className="fw-bold ms-2 ">
                            كود الطلب:
                        </label>
                        <br />
                        <Input.Search size="large" prefix={<i className="fa fa-hashtag text-secondary" />} allowClear onChange={(e) => { e.target.value.length === 0 && orders.length === 1 ? getOrders() : console.log(e.target.value.length === 0 && orders.length === 1); }} onSearch={(e) => getorderById(e)} />
                    </span>

                    <span className="col-2">
                        <label htmlFor="" className="fw-bold ms-2 ">
                            أسم الفني:
                        </label>
                        <br />
                        <Select
                            optionFilterProp="children"
                            filterOption={filterOption}
                            showSearch
                            options={technicians?.map((item) => {
                                return { label: item.name, value: item.code };
                            })}
                            placeholder="أسم الفني"
                            className="w-100"
                            onChange={(e) => {
                                settechnicianID(e);
                            }}
                            size="large"
                            allowClear
                        />
                    </span>

                    <span className="col-2">
                        <label htmlFor="" className="fw-bold ms-2 ">
                            أسم العميل:
                        </label>
                        <br />
                        <Select
                            optionFilterProp="children"
                            filterOption={filterOption}
                            showSearch
                            options={Customers?.map((item) => {
                                return { label: item.name, value: item.code };
                            })}
                            placeholder="أسم العميل"
                            className="w-100"
                            onChange={(e) => {
                                setcustomerID(e);
                            }}
                            size="large"
                            allowClear
                        />
                    </span>

                    <div className="col-4 d-flex align-items-center">
                        <span className="w-50">
                            <label htmlFor="" className="fw-bold ms-2 ">
                                من:
                            </label>
                            <br />
                            <DatePicker
                                placeholder={"اختر التاريخ"}
                                className="w-75"
                                onChange={(e) => {
                                    setfrom(e ? `${e?.$y}-${e?.$M + 1}-${e?.$D}` : e);
                                }}
                                size="large"
                                allowClear
                            />
                        </span>
                        <span className="w-50">
                            <label htmlFor="" className="fw-bold ">
                                الى:
                            </label>
                            <br />
                            <DatePicker
                                placeholder={"اختر التاريخ"}
                                className="w-75"
                                onChange={(e) => {
                                    setto(e ? `${e?.$y}-${e?.$M + 1}-${e?.$D}` : e);
                                }}
                                size="large"
                                allowClear
                            />
                        </span>
                    </div>

                </div>
                <div className="d-flex justify-content-between align-items-center  mt-4 mb-2">
                    <Radio.Group value={statues} size="large" onChange={(e) => { setstatues(e.target.value) }}>
                        <Radio.Button >الكل</Radio.Button>
                        <Radio.Button className="text-success " value={2} >تم استلامه</Radio.Button>
                        <Radio.Button className="text-primary" value={1} >معلق</Radio.Button>
                        <Radio.Button className="text-secondary" value={0} >مسودة</Radio.Button>
                        <Radio.Button className="text-danger" value={3} >ملغي</Radio.Button>
                    </Radio.Group>
                    <Checkbox checked={checked} onChange={onChange} >
                        عرض ما الفواتير القديمه
                    </Checkbox>
                    <Pagination
                        current={current}
                        onChange={PaginationChange}
                        pageSize={5}
                        total={numofPages ? numofPages * 5 : 0}
                        showSizeChanger={false}
                    />

                </div>

                <table className="table table-bordered custom-table">
                    <thead>
                        <tr>
                            <th className="text-center">الحاله</th>
                            <th className="text-center">رقم الطلبية</th>
                            <th className="text-center">اسم العميل</th>
                            <th className="text-center">اسم الفني</th>
                            <th className="text-center">تفاصيل الفاتوره</th>
                            <th className="text-center">سعر الشراء بعد الخصم</th>
                            <th className="text-center">سعر الشراء قبل الخصم</th>
                            <th className="text-center">التاريخ</th>

                            <th className="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders?.map((e) => {
                            return (
                                <tr>
                                    <td className={`fw-bold ${e?.orderStatus === 0 ? 'text-secondary' : e?.orderStatus === 1 ? 'text-primary' : e?.orderStatus === 2 ? 'text-success' : 'text-danger'}`}>
                                        {e?.orderStatus === 0 ? 'مسوده' : e?.orderStatus === 1 ? 'معلق' : e?.orderStatus === 2 ? 'تم' : 'ملغي'}
                                    </td>
                                    <td>{e.orderID}</td>
                                    <td>{e.customerName}</td>
                                    <td>{e.technicalName ? e.technicalName : e.technincalName}</td>
                                    <td>{e.customerDescription ? e.customerDescription : '--'}</td>
                                    <td>{e.totalBeforeDiscount}</td>
                                    <td>{e.totalAfterDiscount}</td>
                                    <td>{e.date}</td>

                                    <td className="d-flex justify-content-end gap-2 align-self-center">

                                        {e?.hasSubOrders ?
                                            <Tooltip title='سجل الطلبيات'>

                                                <Button className='p-0 ' size='small' type="link" onClick={() => getSubOrders(e.orderID)} >
                                                    <i class="fa-solid fa-clipboard-list fa-xl"></i>
                                                </Button>
                                            </Tooltip>
                                            :
                                            ''
                                        }
                                        {e?.orderStatus === 2 ?
                                            <Tooltip title='انشاء طلبيه'>

                                                <Button className='p-0 ' size='small' type="link" onClick={() => setOrderID(e.orderID)} >
                                                    <i class="fa-solid fa-circle-plus fa-xl" ></i>
                                                </Button>
                                            </Tooltip>
                                            :
                                            ''
                                        }

                                        <Tooltip title='تعديل الطلبيه'>

                                            <Button size='small' type="link" onClick={() => handleUpdate(e.orderID)}>
                                                <i className="fa fa-edit fa-lg" />
                                            </Button>
                                        </Tooltip>


                                        {e?.orderStatus === 3 ?
                                            <Popconfirm
                                                placement="topRight"
                                                title="حذف الطلبيه"
                                                description="هل انت متاكد من حذف هذه الطلبيه؟"
                                                onConfirm={() => handleDelete(e.orderID)}
                                                okText="نعم"
                                                cancelText="لا"
                                            >

                                                <Button
                                                    type="link"
                                                    size='small'

                                                    danger
                                                >
                                                    <i className="fa fa-trash  fa-lg" />
                                                </Button>
                                            </Popconfirm>
                                            :
                                            <Tooltip title='الغاء الطلبيه'>
                                                <Button
                                                    type="primary"
                                                    size='small'
                                                    danger
                                                    shape="circle"
                                                    onClick={() => handleCancel(e.orderID)}
                                                >
                                                    <i class="fa-solid fa-xmark "></i>
                                                </Button>
                                            </Tooltip>
                                        }


                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </div>


            <Modal style={{ paddingBottom: 20 }} title={<h3 className='text-center mb-4'> نوع الفاتوره </h3>} open={OrderID} centered closeIcon={false} footer={false} onCancel={() => { setOrderID(false) }}>
                <div className=' d-flex justify-content-between align-items-center gap-5'>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { CreateOrder(false) }}>
                        <i className="fa-solid fa-box fs-1"></i>
                        <h4 className='fw-bold'>صادر</h4>
                    </div>
                    <div className='card   d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { CreateOrder(true) }}>
                        <i className="fa-solid fa-right-left  fs-1"></i>
                        <h4 className='fw-bold   '>مرتجع</h4>
                    </div>
                </div>
            </Modal>

            <Modal width={1000} style={{ paddingBottom: 20 }} title={<h3 className='text-center mb-4'> سجل الطلبات </h3>} open={showSubOrders} centered closeIcon={false} footer={false} onCancel={() => { setshowSubOrders(false) }}>
                <table className="table table-bordered custom-table">
                    <thead>
                        <tr>
                            <th className="text-center">رقم الطلبية</th>
                            <th className="text-center">الحاله</th>
                            {/* <th className="text-center">اسم العميل</th>
              <th className="text-center">اسم الفني</th> */}
                            <th className="text-center">نوع الطلبيه</th>
                            <th className="text-center">سعر الشراء بعد الخصم</th>
                            <th className="text-center">سعر الشراء قبل الخصم</th>
                            <th className="text-center">التاريخ</th>

                            <th className="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {subOrders?.map((e) => {
                            return (
                                <tr>
                                    <td>{e.orderID}</td>

                                    <td className={`fw-bold ${e?.orderStatus === 0 ? 'text-secondary' : e?.orderStatus === 1 ? 'text-primary' : e?.orderStatus === 2 ? 'text-success' : 'text-danger'}`}>
                                        {e?.orderStatus === 0 ? 'مسوده' : e?.orderStatus === 1 ? 'معلق' : e?.orderStatus === 2 ? 'تم' : 'ملغي'}
                                    </td>
                                    {/* <td>{e.customerName}</td> */}
                                    {/* <td>{e.technicalName ? e.technicalName : e.technincalName}</td> */}
                                    {e.isReturn ? <td className="text-danger fw-bold ">مرتجع</td> : <td className="text-primary fw-bold ">صادر</td>}
                                    <td>{e.totalBeforeDiscount}</td>
                                    <td>{e.totalAfterDiscount}</td>
                                    <td>{e.date}</td>

                                    <td className="d-flex justify-content-end gap-2 align-self-center">

                                        {e?.hasSubOrders ?
                                            <Tooltip title='سجل الطلبيات'>

                                                <Button className='p-0 ' size='small' type="link" onClick={() => getSubOrders(e.orderID)} >
                                                    <i class="fa-solid fa-clipboard-list fa-xl"></i>
                                                </Button>
                                            </Tooltip>
                                            :
                                            ''
                                        }


                                        <Tooltip title='تعديل الطلبيه'>

                                            <Button size='small' type="link" onClick={() => handleUpdate(e.orderID)}>
                                                <i className="fa fa-edit fa-lg" />
                                            </Button>
                                        </Tooltip>


                                        {e?.orderStatus === 3 ?
                                            <Popconfirm
                                                placement="topRight"
                                                title="حذف الطلبيه"
                                                description="هل انت متاكد من حذف هذه الطلبيه؟"
                                                onConfirm={async () => { await handleDelete(e.orderID, e?.existOrder); }}
                                                okText="نعم"
                                                cancelText="لا"
                                            >

                                                <Button
                                                    type="link"
                                                    size='small'

                                                    danger
                                                >
                                                    <i className="fa fa-trash  fa-lg" />
                                                </Button>
                                            </Popconfirm>
                                            :
                                            <Tooltip title='الغاء الطلبيه'>
                                                <Button
                                                    type="primary"
                                                    size='small'
                                                    danger
                                                    shape="circle"
                                                    onClick={async () => { await handleCancel(e.orderID, e?.existOrder); }}
                                                >
                                                    <i class="fa-solid fa-xmark "></i>
                                                </Button>
                                            </Tooltip>
                                        }


                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Modal>
        </>
    );
}

export default LastOrders;
