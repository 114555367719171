import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./Components/Login/Login.jsx";
import MainLayout from "./Components/Admin/MainLayout/MainLayout.jsx";
import ProtectedRoute from "./Components/Protected Route/ProtectedRoute.jsx";
import Home from "./Components/Admin/Home/Home.jsx";
import Suppliers from "./Components/Admin/Suppliers/Suppliers.jsx";
import Categories from "./Components/Admin/Categories/Categories.jsx";
import Products from "./Components/Keeper/Products/Products.jsx";
import AddProduct from "./Components/Keeper/Products/Add Product/AddProduct.jsx";
import InternalServerError from "./Components/Errors Handling/Error 500/InternalServerError.jsx";
import Error404 from "./Components/Errors Handling/Error 404/Error404.jsx";
import Stores from "./Components/Admin/Stores/Stores.jsx";
import Users from "./Components/Admin/Users/Users.jsx";
import Taxes from "./Components/Admin/Taxes/Taxes.jsx";
import Technicians from "./Components/Admin/Technicians/Technicians.jsx";
import CasherHome from "./Components/Casher/CasherHome/CasherHome.jsx";
import ProductsInfo from "./Components/Casher/Products/ProductsInfo.jsx";
import PrintOrder from "./Components/Casher/Orders/PrintOrder.jsx";
import LastOrders from "./Components/Casher/LastOrders/LastOrders.jsx";
import Statement from "./Components/Casher/Statement/Statement.jsx";
import Blank from "./Components/Casher/Blank.jsx";
import KeeperHome from "./Components/Keeper/Home/KeeperHome.jsx";
import Needs from "./Components/Keeper/Needs/Needs.jsx";
import Coupons from "./Components/Admin/Coupons/Coupons.jsx";
import Points from "./Components/Casher/Points/Points.jsx";
import CasherLayout from "./Components/Casher/Layout/CasherLayout.jsx";
import ReturnOrder from "./Components/Casher/Orders/CreateOrder/ReturnOrder.jsx";
import OnlineOrders from "./Components/Casher/OnlineOrders/OnlineOrders.jsx";
import NewCreateOrder from "./Components/Casher/Orders/CreateOrder(New)/NewCreateOrder.jsx";

function App() {
  // const baseUrl = 'http://82.112.254.244:8081'
  const baseUrl = 'https://eskander.boslalearning.com'
  // const baseUrl = "https://isckander.voyalix.com";
  // const baseUrl = "https://localhost:7204";
  const routers = createBrowserRouter([
    { index: true, element: <Login baseUrl={baseUrl} /> },
    { path: "login", element: <Login baseUrl={baseUrl} /> },
    {
      path: "/admin",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute role={0}>
              <Home baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "suppliers",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Suppliers baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "categories",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Categories baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "coupons",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Coupons baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "stores",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Stores baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "employees",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Users baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "technicians",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Technicians baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "taxes",
          element: (
            <ProtectedRoute role={0}>
              {" "}
              <Taxes baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        // { path: "add-product", element: <ProtectedRoute> <AddProduct /></ProtectedRoute> },
      ],
    },
    {
      path: "/keeper",
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute role={1}>
              {" "}
              <KeeperHome baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: 'needs',
          element: (
            <ProtectedRoute role={1}>
              {" "}
              <Needs baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: 'products',
          element: (
            <ProtectedRoute role={1}>
              {" "}
              <Products baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "add-product",
          element: (
            <ProtectedRoute role={1}>
              {" "}
              <AddProduct baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/casher",
      element: <CasherLayout />,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <CasherHome baseUrl={baseUrl} />{" "}
            </ProtectedRoute>
          ),
        },
        {
          path: "home",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <CasherHome baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "add-order",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <NewCreateOrder baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "check",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <NewCreateOrder baseUrl={baseUrl} ischeck={true} />
            </ProtectedRoute>
          ),
        },
        {
          path: "return-order",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <ReturnOrder baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "products-info",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <ProductsInfo baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "points",
          element: (
            <ProtectedRoute role={2}>
              <Points baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "online-orders",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <OnlineOrders baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "statement",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <Statement baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
     
        {
          path: "blank/:id",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <Blank baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },
        {
          path: "last-orders",
          element: (
            <ProtectedRoute role={2}>
              {" "}
              <LastOrders baseUrl={baseUrl} />
            </ProtectedRoute>
          ),
        },

        // { path: "categories", element: <ProtectedRoute role={2}> <Categories  baseUrl={baseUrl}/></ProtectedRoute> },
        // { path: "products", element: <ProtectedRoute role={2}> <Products baseUrl={baseUrl} /></ProtectedRoute> },
        // { path: "add-product", element: <ProtectedRoute> <AddProduct baseUrl={baseUrl} /></ProtectedRoute> },
      ],
    },
    {
      path: "print-order",
      element: (
        <ProtectedRoute role={2}>
          {" "}
          <PrintOrder baseUrl={baseUrl} />
        </ProtectedRoute>
      ),
    },
    { path: "server-error", element: <InternalServerError /> },
    { path: "not-found", element: <Error404 /> },
    { path: "*", element: <Error404 /> },
  ]);

  return (
    <>

        <RouterProvider router={routers} />
    </>
  );
}

export default App;
